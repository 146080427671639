










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import MaxOrderAmountForDeferredPaymentEditor from "@/components/organisms/shop_setting/MaxOrderAmountForDeferredPaymentEditor.vue";

export default {
  components: {
    SubPageTemplate,
    MaxOrderAmountForDeferredPaymentEditor,
  },
};
